@import "../../../styles/variables.scss";

#report_page {
  .ant-collapse {
    margin: 1rem 0;
    .ant-divider-horizontal.ant-divider-with-text {
      margin: 1rem 0;
    }
    .ant-collapse-content-box {
      padding-top: 0;
    }
  }
  .report-btn {
    margin-bottom: 1rem;
    // float: right;
  }
  .sort_options {
    margin: 1rem 0 2rem;
  }
}
