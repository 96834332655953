@import "../../styles/variables.scss";

.auth-container {
  display: flex;
  position: relative;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
  .auth-header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100px;
    padding: 0 30px;
    @include responsive {
      padding: 0 1rem;
    }
    .auth-logo {
      width: 150px;
    }
    .auth-header-button-container {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
    .auth-header-button {
      font-weight: 800;
      font-size: 14px;
      line-height: 16px;
      transition: transform 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
      font-family: "Gotham Pro", "Proxima Nova", arial, serif;
      width: 92px;
      height: 40px;
      background-color: $tik-tunes-pink;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      cursor: pointer;
      text-transform: capitalize;
    }
    .auth-header-button-inverted {
      background-color: white;
      color: $tik-tunes-pink;
    }
  }

  .form-container {
    flex-grow: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .main-form {
      width: 480px;
      max-height: 75vh;
      background: #fff;
      box-shadow: 0 12px 35px rgba(16, 30, 54, 0.25);
      border-radius: 6px;
      padding: 30px 30px 26px;
      margin-top: -75px;
      position: relative;
      overflow-y: auto;
      .form-header-message {
        font-weight: 700;
        font-size: 32px;
        line-height: 46px;
        font-family: "Gotham Pro", "Proxima Nova", arial, serif;
        color: #292d34;
        text-align: center;
        margin-bottom: 20px;
      }
      .ant-form-item {
        margin-bottom: 1rem;
      }
      .ant-input {
        @include responsive {
          font-size: 16px;
        }
      }
      .ant-form-item-label {
        padding: 0 0 0px;
        label {
          font-weight: 500;
          font-size: 1em;
          line-height: 11px;
          font-family: "Gotham Pro", "Proxima Nova", arial, serif;
          color: #292d34;
          padding: 0 0;
        }
      }
      .login-error {
        color: red;
        margin-bottom: 0em;
      }
      .ant-btn-primary {
        width: 100%;
        height: 50px;
        font-weight: 800;
        background-color: $tik-tunes-pink;
        border-width: 0px;
      }
    }
  }
  .long-form-input .ant-form-item-label {
    // padding: 1.1rem 0 !important;
    line-height: 4.5rem !important;
    label {
      line-height: 1.1em !important;
    }
  }
}
