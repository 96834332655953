@import "../../styles/variables.scss";
#home-page {
  .ant-page-header-content {
    padding: 0.25rem 1rem !important;
    background-color: #ffffff;
  }

  .chart-container {
    position: relative;
    min-height: auto;
    width: auto;
  }

  .ant-page-header-heading-extra {
    @include phones {
      width: auto !important;
    }
  }
  .performance_card {
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    color: #1c273c;
    letter-spacing: -0.5px;
    .ant-card-head {
      padding: 0;
      font-size: 1.5rem;
      min-height: 25px;
      .ant-card-head-title {
        padding-bottom: 0;
        font-size: 1rem;
        @include phones {
          font-size: 0.75rem;
        }
      }
    }

    .ant-card-body {
      padding: 0;
      font-size: 1.5rem;
      font-weight: 700;

      margin-bottom: 3px;
    }
  }
  .account_total_list {
    .ant-list-item {
      padding: 8px 0 !important;
    }
    .list_amount {
      display: block;
      margin-left: auto;
      font-weight: 600;
      font-family: "Archivo", Arial, sans-serif;
      color: #1c273c;
    }
  }
}
