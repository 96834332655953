@import "../../../styles/variables.scss";
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  width: 600px !important;
  @include tablet {
    width: 500px !important;
  }
  @include phones {
    width: 100% !important;
  }
}
.profileDrawer {
  .editUserForm {
    font-size: 16px !important;
    .ant-form-item-control-input {
      min-height: 0 !important;
    }
    .ant-input {
      font-size: 16px !important;
    }
  }

  .ant-list-item-action {
    margin-left: 12px !important;
  }
  .ant-list-item-action > li {
    padding: 0 9px;
  }
  .action_button {
    padding: 0px !important;
  }
}
.ant-input {
  font-size: 16px !important;
}
