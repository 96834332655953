@import "../../../styles/variables.scss";
.affiliate_page {
  height: inherit;
  #scrollableDiv {
    height: 65vh !important;
    overflow: auto;
  }
  .ant-list-header {
    @include responsive {
      display: none;
    }
    padding: 0.5rem 1rem;
    background-color: #f2f4fa;
  }
  .ant-spin-nested-loading {
    padding: 0.25rem 0.5rem;
    min-height: 60vh;
  }
  .ant-spin-nested-loading > div > .ant-spin {
    max-height: 65vh;
  }
  .ant-list-items {
    margin-top: 0.5rem;
  }
  .list_page_header {
    width: 100%;
  }
  .list_page_header .ant-page-header-heading-extra {
    width: auto !important;
  }
  .ant-pagination {
    padding: 0 1rem;
  }
}
