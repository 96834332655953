@import "../../styles/variables.scss";

#dashboard-container {
  min-height: 100vh;
  /***** HEADER  *****/

  .logo {
    margin: 0 1rem;
    .tik-tunes-logo {
      max-width: 150px;
      width: 100%;
    }
  }
  .site-layout-sub-header-background {
    background: $light-blue;
  }

  /***** / HEADER  *****/

  /***** SIDEBAR  *****/
  .ant-layout-sider {
    background-color: $dark-grey;
    .ant-menu.ant-menu-dark {
      margin-top: 3.2rem;
      background-color: $light-grey;
      .ant-menu-inline.ant-menu-sub {
        background-color: $dark-grey;
      }
      .ant-menu-item > a {
        color: white;
      }
      .ant-menu-item-selected {
        background: $light-blue;
      }
    }
  }
  /***** /SIDEBAR  *****/

  /***** CONTENT  *****/

  .ant-layout-content {
    background-color: $main-white;
    .breadcrumb {
      text-transform: capitalize;
    }
    .site-layout-background {
      background: #fff;
      padding: 1rem;
      min-height: 85vh;
      .main_page_header {
        padding: 0;
        box-shadow: 0 0 2px 0 rgba(27, 34, 46, 0.12),
          0 2px 4px 0 rgba(27, 34, 46, 0.2);
        .ant-page-header-heading {
          background-color: $light-blue;
          padding: 0.25rem 1rem;
          border-top-right-radius: 2px;
          border-top-left-radius: 2px;

          .ant-page-header-heading-title {
            color: white;
          }
        }
        .ant-page-header-content {
          padding: 0 0 1rem;
          border: 1px solid $light-blue;
        }
        .ant-input {
          font-size: 16px !important;
        }
        .ant-page-header-heading-extra {
          @include phones {
            width: 100%;
          }
        }
      }
    }
  }

  /***** /CONTENT  *****/
}
